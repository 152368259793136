<template>
  <div class="bt-transaksi pa-5">
    <h6 class="text-h5 font-weight-bold orange--text text--lighten-1 d-flex align-center">
      <div class="rounded-pill orange lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3">
        <v-icon small color="white">mdi-wallet-plus</v-icon>
      </div>
      Registrasi Taber
    </h6>
    <v-row class="mt-5">
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Rembug</label>
        <v-select
          color="black"
          v-model="form.data.rembug"
          :items="opt.rembug"
          @change="getAnggota()"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Anggota</label>
        <v-select
          color="black"
          v-model="form.data.cif_no"
          :items="opt.anggota"
          @change="getProduct(), setProfile()"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
          <label for="">No Anggota</label>
          <v-text-field v-model="form.data.cif_no" color="black" autocomplete="false" hide-details flat solo dense disabled/>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
          <label for="">Nama Lengkap</label>
          <v-text-field v-model="form.profile.nama" color="black" autocomplete="false" hide-details flat solo dense disabled/>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
          <label for="">Rembug</label>
          <v-text-field v-model="form.profile.cm_name" color="black" autocomplete="false" hide-details flat solo dense disabled/>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
          <label for="">Alamat</label>
          <v-text-field v-model="form.profile.alamat" color="black" autocomplete="false" hide-details flat solo dense disabled/>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
          <label for="">Produk</label>
          <v-select v-model="form.data.product_code" :items="opt.product" 
          @change="setRekening()" color="black" hide-details flat solo dense/>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
          <label for="">Biaya Administrasi</label>
          <vue-numeric v-model="form.data.biaya_administrasi" currency="Rp " separator="." class="white w-100 pa-2" disabled/>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
          <label for="">No Rekening</label>
          <v-text-field v-model="form.data.account_saving_no" color="black" autocomplete="false" hide-details flat solo dense disabled/>
      </v-col>
      <v-col cols="12" class="py-0 mb-3 mt-5">
          <h4>Tabungan Berencana</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
          <label for="">Setoran</label>
          <vue-numeric v-model="form.data.setoran" currency="Rp " separator="." class="white w-100 pa-2"/>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
          <label for="">Periode Setoran</label>
          <v-select v-model="form.data.periode_setoran" :items="opt.periode_setoran" color="black" hide-details flat solo dense/>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
          <label for="">Jangka Waktu</label>
          <v-text-field v-model="form.data.jangka_waktu
          " color="black" autocomplete="false" hide-details flat solo dense/>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
          <label for="">Rencana Awal Setoran</label>
          <v-text-field v-model="form.data.rencana_awal_setoran
          " color="black" autocomplete="false" hide-details flat solo dense type="date"/>
      </v-col>
    </v-row>
  
    <div class="d-flex justify-center mt-4 pt-4">
        <v-btn class="orange lighten-1 white--text mx-2">Kembali</v-btn>
        <v-btn class="orange lighten-1 white--text mx-2" :disabled="form.loading" @click="doRegisterTabungan()">Simpan</v-btn>
    </div>
    <v-snackbar v-model="alert.show" :timeout="3000">
      {{ alert.msg }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
export default {
  name: "TabunganMasuk",
  components: {
  },
  data: () => ({
    form: {
      data: {
        rembug: null,
        cif_no: null,
        product_code: null,
        account_saving_no: null,
        biaya_administrasi: 0,
        setoran: 0,
        periode_setoran: 1,
        jangka_waktu: 0,
        rencana_awal_setoran: null,
        tanggal_jatuh_tempo: null,
        tanggal_pembukaan: null
      },
      profile: {
        nama: null,
        cm_name: null,
        alamat: null
      }
    },
    opt: {
      rembug: [],
      anggota: [],
      product: [],
      periode_setoran: [
        {
          value: 0,
          text: 'Bulanan'
        },
        {
          value: 1,
          text: 'Mingguan'
        },
        {
          value: 2,
          text: 'Harian'
        }
      ]
    },
    alert: {
      show: false,
      msg: null,
    },
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getRembug() {
      let day = new Date().getDay();
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("day", day);
      // payload.append("day", 1);
      try {
        let req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.rembug = [];
          data.map((item) => {
            let opt = {
              value: item.cm_code,
              text: item.cm_name,
              data: item,
            };
            this.opt.rembug.push(opt);
          });
        } else {
          this.alert = {
            show: true,
            msg: 'Gagal mendapatkan data Rembug',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getAnggota() {
      let payload = new FormData();
      payload.append("cm_code", this.form.data.rembug);
      try {
        let req = await services.infoMember(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.anggota = [];
          data.map((item) => {
            let opt = {
              value: item.cif_no,
              text: item.nama,
              data: item,
            };
            this.opt.anggota.push(opt);
          });
        } else {
          this.alert = {
            show: true,
            msg: 'Gagal mendapatkan data Anggota',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getProduct() {
      let payload = new FormData();
      payload.append("cif_no", this.form.data.cif_no);
      try {
        let req = await services.productSaving(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.product = [];
          data.map((item) => {
            let opt = {
              value: item.product_code,
              text: item.product_name,
              data: item,
            };
            this.opt.product.push(opt);
          });
        } else {
          this.alert = {
            show: true,
            msg: 'Gagal mendapatkan data Anggota',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async doRegisterTabungan() {
      let payload = new FormData();
      let payloadData = this.form.data;
      for (let key in payloadData) {
        payload.append(key, payloadData[key]);
      }
      this.form.loading = true;
      try {
        let req = await services.registrasiTaber(
          payload,
          this.user.token
        );
        if (req.status === 200) {
          this.alert = {
            show: true,
            msg: "Registrasi taber berhasil",
          };
          setTimeout(() => {
            this.$router.push(`/tabungan`);
          }, 1000);
        } else {
          this.alert = {
            show: true,
            msg: "Registrasi taber gagal",
          };
        }
        this.form.loading = false;
      } catch (error) {
        this.form.loading = false;
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    setProfile() {
      let profile = this.opt.anggota.find((item) => item.data.cif_no == this.form.data.cif_no)
      this.form.profile = profile.data
    },
    setRekening() {
      let rekening = this.opt.product.find((item) => item.data.product_code == this.form.data.product_code)
      this.form.data.account_saving_no = rekening.data.account_saving_no
      this.form.data.biaya_administrasi = rekening.data.biaya_administrasi
    },
    notif(title, msg, type) {
      this.alert = {
        show: true,
        msg: msg,
      };
    },
  },
  mounted() {
    this.getRembug()
  }
};
</script>
